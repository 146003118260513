<template>
    <div class="columns">
        <div class="column is-1">
            <div 
                class="instructions-card-number" 
                v-bind:class="active ? 'active' : null">
                <p>{{ order }}</p>
            </div>
        </div>

        <div 
            class="column is-11 instructions-card-text-col"
            v-bind:class="active ? 'active' : null">
            <p>{{ text }}</p>
        </div>
    </div>
</template>

<script scoped>

export default {
  name: 'InstructionsListElement',
  props: {
      active: {
          type: Boolean,
          required: true 
      },
      order: {
          type: Number,
          required: true 
      },
      text: {
          type: String,
          required: true
      }
  }
}


</script>

<style lang="scss" scoped>

.instructions-card-number {
    border-radius: 50%;
    height: 80px;
    width: 80px;
    position: relative;
    border: 5px solid #999999;
    -webkit-transition: all 0.3s ease-in;
    -moz-transition: all 0.3s ease-in;
    -o-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in; 
}

.instructions-card-number.active {
    background-color: #FF5800;
    border: none;

    -webkit-transition: all 0.3s ease-in;
    -moz-transition: all 0.3s ease-in;
    -o-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in; 
}

.instructions-card-number.active p {
    color: white;
}

.instructions-card-number p {
    color: #999999;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    font-family: "Colfax-Bold";
    font-size: 35px;
}

.instructions-card-text-col {
    position: relative;
}

.instructions-card-text-col p {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    padding-left: $InstructionCardPadding / 2;
    font-size: 30px;
    text-align: left;
    color: rgba(0, 0, 0, 0.34);
    font-family: "Colfax-Medium";
    font-weight: normal;
}

.instructions-card-text-col.active p {
    color: #000000;
}

.text-active p {
    color: black;
}

</style>