<template>
  <div class="container is-fluid">
    <div class="section intro-section">
      <div>
        <video
          ref="transparent_video"
          autoplay
          :src="content.tip_video"
          playsinline
          class="transparent-full-screen-video"
          @ended="onEnd()"
        ></video>
      </div>
      <div ref="instructions_card" class="instructions_card">
        <InstructionsCard containerSize="small">
          <div class="columns">
            <div class="column"></div>
            <CardTitle class="intro" :text="content.tip_title" />
            <div class="column"></div>
          </div>
          <div class="instructions-steps-container">
            <InstructionsListElement
              v-for="step in steps"
              v-bind:key="step.id"
              :order="step.index"
              :active="step.active"
              :text="step.text"
            />
          </div>
        </InstructionsCard>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import InstructionsCard from "@/components/InstructionsCard.vue";
import CardTitle from "@/components/CardTitle.vue";
import InstructionsListElement from "@/components/InstructionsListElement.vue";
import { createAnalyticsEvent } from "@/BackendClient";
import { mapMutations } from "vuex";

export default {
  name: "intro",
  components: {
    InstructionsCard,
    CardTitle,
    InstructionsListElement
  },
  data: function() {
    return {
      currentActiveStep: 0
    };
  },
  computed: {
    content() {
      return this.$store.getters.content;
    },
    steps() {
      return [
        {
          text: this.content.tip_1,
          index: 1,
          active: true
        },
        {
          text: this.content.tip_2,
          index: 2,
          active: false
        },
        {
          text: this.content.tip_3,
          index: 3,
          active: false
        }
      ];
    }
  },
  mounted: function() {
    createAnalyticsEvent("unique_session_started"); 
    let initialDelay = this.getEmsDataPropertyText("tip_1_timing_page2");
    let self = this;
    
    // Schedule all the instruction item focus transitions
    window.setTimeout(function() {
      // Check if we are in the correct route
      if(self.$route.name === 'intro') {
        self.$refs.instructions_card.style.display = "block";
        self.$refs.transparent_video.style.width = "40%";
      }
      self.currentActiveStep++;
    }, initialDelay);
  },
  watch: {
    currentActiveStep: {
      handler(currentActiveStep) {
        this.increaseListCounter(
          this.getEmsDataPropertyText(
            `tip_${currentActiveStep + 1}_timing_page2`
          )
        );
      }
    }
  },
  methods: {
    increaseListCounter: function(delay) {
      var self = this;
      window.setTimeout(function() {
        for (var i = 0; i < self.steps.length - 1; i++) {
          // Shift focus to the next element in the
          // instructions list
          if (self.steps[i].active === true) {
            self.currentActiveStep++;
            self.steps[i].active = false;
            self.steps[i + 1].active = true;
            self.$forceUpdate();
            break;
          }
        }
      }, delay);
    },
    onEnd: function() {
      this.$router.push({name: "choose-background"});
    },
    ...mapMutations(["SET_PAGE2_STEP_ACTIVE"])
  }
};
</script>

<style lang="scss" scoped>
.transparent-full-screen-video {
  opacity: 1;
  filter: blur(0px);
  position: fixed;
  right: 0;
  bottom: 0;
  width: 100%;
  z-index: 3;
  transition: width, 2s;
}
.instructions_card {
  display: none;
}

.list-item-transition {
  &-enter-active {
    transition: opacity 350ms;
  }
  &-enter-to {
    opacity: 1;
  }
  &-enter {
    opacity: 0;
  }
}
</style>